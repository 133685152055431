import $ from './jquery';
import { recomputeStyle } from './internal/animation';
import amdify from './internal/amdify';
import globalize from './internal/globalize';
import keyCode from './key-code';
import CustomEvent from './polyfills/custom-event';
import escapeHtml from './escape-html';
import {CLOSE_BUTTON} from './close-button';

var AUTO_CLOSE_TIME = 5000;
var ID_FLAG_CONTAINER = 'aui-flag-container';
var defaultOptions = {
    body: '',
    close: 'manual',
    title: '',
    type: 'info'
};

function flag (options) {
    options = $.extend({}, defaultOptions, options);

    // clean the title value
    options.title = (options.title || '').toString().trim();

    var $flag = renderFlagElement(options);
    extendFlagElement($flag);

    if (options.close === 'auto') {
        makeCloseable($flag);
        makeAutoClosable($flag);
    } else if (options.close === 'manual') {
        makeCloseable($flag);
    }

    handleFlagContainer();
    insertFlag($flag);

    return $flag.get(0);
}

function extendFlagElement ($flag) {
    var flag = $flag[0];

    flag.close = function () {
        closeFlag($flag);
    };
}

function renderFlagElement ({body, title, close, type}) {
    const titleHtml = title ? `<p class="title"><strong>${escapeHtml(title)}</strong></p>` : '';
    const html = `<div class="aui-message">${titleHtml}</div>`;

    const $message = $(html)
        .append($.parseHTML(body || ''))
        .addClass(close === 'never' ? '' : 'closeable')
        .addClass(`aui-message-${type}`);
    return $('<div class="aui-flag" aria-live="polite"></div>').append($message);
}

function makeCloseable ($flag) {
    var $icon = $(CLOSE_BUTTON);

    $icon.on('click', function () {
        closeFlag($flag);
    });

    $icon.on('keypress', function (e) {
        if ((e.which === keyCode.ENTER) || (e.which === keyCode.SPACE)) {
            closeFlag($flag);
            e.preventDefault();
        }
    });

    return $flag.find('.aui-message').append($icon)[0];
}

function makeAutoClosable ($flag) {
    $flag.find('.aui-message').addClass('aui-will-close');
    setTimeout(function () {
        $flag[0].close();
    }, AUTO_CLOSE_TIME);
}

function closeFlag ($flagToClose) {
    var flag = $flagToClose.get(0);

    flag.removeAttribute('open');
    flag.dispatchEvent(new CustomEvent('aui-flag-close', {bubbles: true}));

    return flag;
}

function handleFlagContainer () {
    const container = findContainer();

    if (container) {
        const closedFlags = container.querySelectorAll('.aui-flag:not([open])');

        Array.from(closedFlags).map(flag => flag.parentNode.removeChild(flag));
    } else {
        $('body').prepend('<div id="' + ID_FLAG_CONTAINER + '"></div>');
    }
}

function findContainer () {
    return document.getElementById(ID_FLAG_CONTAINER);
}

function insertFlag ($flag) {
    const flagContainer = findContainer();

    $flag.appendTo(flagContainer);
    recomputeStyle($flag);

    $flag
        .removeAttr('hidden')
        .attr('open','');
}

amdify('aui/flag', flag);
globalize('flag', flag);
export default flag;
