import template from 'skatejs-template-html';

export default template(`
    <input type="text" class="text" autocomplete="off" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false">
    <select></select>
    <datalist>
        <content select="aui-option"></content>
    </datalist>
    <button class="aui-button" role="button" tabindex="-1" type="button"></button>
    <div class="aui-popover" role="listbox" data-aui-alignment="bottom left" hidden>
        <ul class="aui-optionlist" role="presentation"></ul>
    </div>
    <div class="aui-select-status assistive" aria-live="polite" role="status"></div>
`);
