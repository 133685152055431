import $ from './jquery';
import amdify from './internal/amdify';
import globalize from './internal/globalize';
import layerWidget from './layer';
import widget from './internal/widget';
import keyCode from './key-code';
import {CLOSE_BUTTON, CLOSE_BUTTON_CLASS_SELECTOR} from './close-button';

var defaults = {
    'aui-focus': 'false', // do not focus by default as it's overridden below
    'aui-blanketed': 'true'
};

function applyDefaults($el) {
    $.each(defaults, function (key, value) {
        var dataKey = 'data-' + key;
        if (!$el[0].hasAttribute(dataKey)) {
            $el.attr(dataKey, value);
        }
    });
}

function Dialog2(selector) {
    this._handlers = new WeakMap();
    if (selector) {
        this.$el = $(selector);
    } else {
        this.$el = $(`
        <section role="dialog" class="aui-layer aui-dialog2 aui-dialog2-medium">
            <header class="aui-dialog2-header">
                <h2 class="aui-dialog2-header-main"></h2>
                ${CLOSE_BUTTON}
                </button>
            </header>
            <div class="aui-dialog2-content"></div>
            <footer class="aui-dialog2-footer"></footer>
        </section>`);
    }
    applyDefaults(this.$el);
}


Dialog2.prototype.on = function (event, fn) {
    const $el = this.$el;
    if (!this._handlers.get(fn)) {
        const handler = function(e) {
            if (e.target === $el.get(0)) {
                fn.apply(this, arguments);
            }
        };
        layerWidget($el).on(event, handler);
        this._handlers.set(fn, handler);
    }
    return this;
};

Dialog2.prototype.off = function (event, fn) {
    const $el = this.$el;
    const handler = this._handlers.get(fn);
    if (handler) {
        layerWidget($el).off(event, handler);
        this._handlers.delete(fn);
    }
    return this;
};

Dialog2.prototype.show = function () {
    layerWidget(this.$el).show();
    return this;
};

Dialog2.prototype.hide = function () {
    layerWidget(this.$el).hide();
    return this;
};

Dialog2.prototype.remove = function () {
    layerWidget(this.$el).remove();
    return this;
};

Dialog2.prototype.isVisible = function () {
    return layerWidget(this.$el).isVisible();
};

var dialog2Widget = widget('dialog2', Dialog2);
var dialog2GlobalHandlers = new Set();

dialog2Widget.on = function (eventName, fn) {
    if (!dialog2GlobalHandlers.has(fn)) {
        layerWidget.on(eventName, '.aui-dialog2', fn);
        dialog2GlobalHandlers.add(fn);
    }
    return this;
};

dialog2Widget.off = function (eventName, fn) {
    if (dialog2GlobalHandlers.has(fn)) {
        layerWidget.off(eventName, '.aui-dialog2', fn);
        dialog2GlobalHandlers.delete(fn);
    }
    return this;
};

/* Live events */

$(document).on('click keydown', `.aui-dialog2-header ${CLOSE_BUTTON_CLASS_SELECTOR}`, function(e) {
    const shouldHandle = e.type === 'click' || e.keyCode === keyCode.ENTER || e.keyCode === keyCode.SPACE;
    if (shouldHandle) {
        e.preventDefault();
        dialog2Widget($(e.target).closest('.aui-dialog2')).hide();
    }
});

dialog2Widget.on('show', function (e, $el) {
    // prevent inability to focus the close button.
    $el.find(CLOSE_BUTTON_CLASS_SELECTOR).attr('tabindex', 0);
    $el.attr('tabindex', -1);
    // find appropriate content to focus, in order of importance of the content.
    var focusSelector = $el[0].hasAttribute('data-aui-focus-selector') && $el.attr('data-aui-focus-selector');
    if (focusSelector) {
        var $elementToFocus = $el.find(focusSelector);
        if ($elementToFocus.length > 0) {
            return $elementToFocus.first().focus();
        }
    }

    // focus the dialog element itself #a11y #FEP-607
    $el.focus();
});

dialog2Widget.on('hide', function (e,$el) {
    var layer = layerWidget($el);

    if ($el.data('aui-remove-on-hide')) {
        layer.remove();
    }
});

amdify('aui/dialog2', dialog2Widget);
globalize('dialog2', dialog2Widget);
export default dialog2Widget;
